import React from 'react';

export default () => (
  <>
    <div className="container h4">
      <p>
        <strong>Privatnost</strong>
      </p>

      <p>
        Prodajno mjesto mora kupcima osigurati mogućnost da ne pristanu na
        sudjelovanje u marketinškim akcijama i da ne budu suglasni s davanjem
        svojih osobnih podataka na korištenje trećim osobama.
      </p>

      <p>
        <strong>Izjava o zaštiti i prikupljanju osobnih podataka</strong>
      </p>

      <p>
        Obavezujemo se pružati zaštitu osobnim podacima kupaca, na način da
        prikupljamo samo nužne, osnovne podatke o kupcima / korisnicima koji su
        nužni za ispunjenje naših obveza; informiramo kupceo načinu korištenja
        prikupljenih podataka, redovito dajemo kupcima mogućnost izbora o
        upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne
        da se njihovo ime ukloni s lista koje se koriste za marketinške
        kampanje.
      </p>

      <p>
        Radi potrebe unapređenja prodaje i podrške kupcima/korisnicima tijekom
        perioda od 15 dana besplatnog korištenja Kokolingo digitalne vježbenice,
        kupcima/korisnicima omogućujemo direktan telefonski kontakt s našom
        službom podrške koja će pozivom pružiti telefonsku podršku. Na taj način
        želimo poduzeti sve potrebne mjere kako bismo pomogli
        kupcima/korisnicima tijekom perioda testiranja i omogućili maksimalnu
        iskoristivost svih vrijednosti Kokolingo digitalne vježbenice za djecu s
        artikulacijskim poremećajima.
      </p>

      <p>Telefonski broj prikupljamo zato da bismo mogli:</p>
      <p>
        *omogućiti Vam direktni kontakt i savjetovanje s našim stručnim timom
        tijekom perioda besplatnog korištenja Kokolingo digitalne vježbenice.
      </p>
      <p>
        *odgovoriti direktno na Vaš upit i pomoći savjetom oko korištenja i
        vježbe s djetetom.
      </p>
      <p>Email adresu prikupljamo zato da bismo mogli:</p>
      <p>
        *obavijestiti Vas o ponudi naših usluga i poslati Vam informaciju o
        dodatnim pogodnostima koje omogućujemo našim korisnicima/kupcima.
      </p>
      <p>
        *poslati Vam posebno kreiran sadržaj našeg stručnog tima, a koji je
        namijenjen roditeljima kao pomoć ipodrška u radu s djecom.
      </p>
      <p>
        Svi se podaci o korisnicima strogo čuvaju i dostupni su samo
        djelatnicima kojima su ti podaci nužni za obavljanje posla. Svi naši
        djelatnici i poslovni partneri odgovorni su za poštivanje načela zaštite
        privatnost.
      </p>
      <p>
        Imate pravo u svakom trenutku zatražiti pismenim putem i povući danu
        privolu za korištenje osobnih podataka u svrhe kako je prethodno
        navedeno te nas možete kontaktirati na email info@kokolingo.hr, pozivom
        na broj telefona 051 670 379 ili dostavom zahtjeva na adresu: Miroslava
        Krleže 4, 51000 Rijeka.
      </p>
    </div>
  </>
);
