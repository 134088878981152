import * as React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';
import { Link } from 'gatsby';

import {
  Title,
  BlogTitle,
  BlogSubtitle,
  BlogImage,
  BackToTopButton,
  BlogContent,
} from '../../components';

import { Page, HubspotCouponForm } from '..';

import routes from '../../routes';

import coupon1 from '../../images/poklon-bon-blog.png';
import coupon2 from '../../images/poklon-bon.png';

export default () => {
  const messages = useIntlMessages();

  return (
    <Page
      title={messages.meta.GiftKokolingoTitle}
      description={messages.meta.GiftKokolingoDescription}
    >
      <div className="coupon-page">
        <Title className="coupon-page__title fluid no-margin" variant="dark">
          KOKOLINGO POKLON BON
        </Title>
        <div className="coupon__container">
          <BlogContent className="coupon__blog fluid">
            <div>
              <BlogTitle>
                SAVRŠEN POKLON ZA DIJETE KOJE UČI
                <br />
                PRAVILAN IZGOVOR GLASOVA
              </BlogTitle>
              <p>
                Kokolingo poklon bon idealan je poklon za povezivanje s
                najbližima.
                <br />
                Njime darujete vjetar u leđa djetetu koje uči pravilan izgovor
                glasova.
              </p>
              <p>
                Avantura u društvu papigice Koko i gusara Srećka pomoći će
                djetetu da ozbiljne vježbe ne budu napor već igra i zabava.
              </p>
              <p>
                <b>
                  Svakom djetetu treba podrška kako bi napredovalo u razvoju.
                  Ovo je prilika da upravo Vi budete heroj koji će pomoći
                  djetetu s artikulacijskim poremećajima.
                </b>
              </p>
              <BlogSubtitle>
                Kako izgleda kad dijete dobije poklon bon?
              </BlogSubtitle>
              <p>
                Na kućnu adresu stiže pismo papigice Koko djetetu koje dobiva
                ovaj poklon. U veseloj kuverti koja stiže iz Kokolingo svijeta
                biti će poklon bon i pismo papigice Koko s poticajnom porukom za
                dijete. Pored toga, Vama ćemo dodatno putem e-maila poslati
                kopiju poklon bona.
              </p>
              <p>
                Ako Vam tako nešto ne odgovara i želite sami poslati poklon bon
                mi ćemo Vam poslati poklon bon mailom kao PDF dokument koji Vi
                možete dalje proslijediti mailom na željenu adresu ili ga možete
                isprintati i takvog pokloniti.
              </p>
              <BlogImage
                imgAlt="Papiga Koko i poklon bon"
                imgTitle="Poklon bon"
                imgSrc={coupon1}
              />
              <BlogSubtitle>Koje su cijene?</BlogSubtitle>
              <p>
                U ponudi su Kokolingo poklon bonovi <b>Premium</b>, <b>Smart</b>{' '}
                i <b>Mini</b> za korištenje Kokolinga na period od <b>1</b>,
                <b>3</b>, <b>6</b> ili <b>9</b> mjeseci. Opis svakog Kokolingo
                paketa i cijena za mjesec korištenja nalazi se ovdje:{' '}
                <Link
                  to={routes.PARENTS_ANCHOR}
                  target="_blank"
                  rel="noopener noreferrer"
                  hrefLang="hr"
                >
                  https://www.kokolingo.hr/#roditelji
                </Link>
              </p>
              <p>
                <b>
                  Ako uzimate poklon bon za više mjeseci onda dobivate dodatne
                  popuste
                </b>
                : 5% popusta za poklon bon od 3 mjeseca, 10% za 6 mjeseci i 15%
                za 9 mjeseci. Sve cijene navedene su <b>u obrascu</b> u
                nastavku.
              </p>
              <BlogSubtitle>Kako se prijaviti?</BlogSubtitle>
              <p>
                Ako želite kupiti ovaj poklon bon i pokloniti ga nekome trebate
                samo <b>ispuniti</b> ovaj kratki <b>obrazac</b> i mi ćemo Vam
                poslati ponudu za plaćanje.
              </p>

              <HubspotCouponForm />

              <h2>
                <Title
                  className="coupon-page__title fluid no-margin"
                  variant="dark"
                >
                  Kokolingo poklon bon prilika je da razveselite dijete i
                  omogućite mu napredak u poticajnom okruženju Kokolingo
                  svijeta. Neka to bude Vaš poklon djetetu!
                </Title>
              </h2>
              <BlogImage
                imgAlt="Kokolingo poklon bon"
                imgTitle="Poklon bon"
                imgSrc={coupon2}
                className="infographics"
              />
              <p>
                Na raspolaganju smo za sve dodatne informacije putem:
                <br />
                <a href="mailto:info@kokolingo.hr">info@kokolingo.hr</a> i na
                broj <a href="tel:051670379">051 670 379</a>.
              </p>
            </div>
          </BlogContent>
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};
