import React from 'react';

export default () => (
  <div className="container">
    <p>
      1. Ovi uvjeti odnose se na prodaju proizvoda objavljenih na Internetskoj
      stranici www.kokolingo.hr. Korištenjem ovih internetskih stranica Korisnik
      stranice www.kokolingo.hr / Kupac potvrđuje da je upoznat i suglasan s
      Općim uvjetima poslovanja i uvjetima kupnje. Smatra se, da narudžbom i u
      cijelosti prihvaćaju Opći uvjeti poslovanja i uvjeti kupnje. Korištenjem
      ovih Internetskih stranica Korisnik potvrđuje da je upoznat i suglasan s
      Općim uvjetima poslovanja i korištenja. Nositelj svih prava na Internet
      stranici www.kokolingo.hr je trgovačko društvo E-Glas d.o.o. (u daljnjem
      tekstu E-Glas).{' '}
    </p>

    <p>
      2. Uvjeti korištenja propisuju uvjete i pravila za Korisnike i odnose se
      na korištenje Internetske stranice www.kokolingo.hr. Korištenjem
      Internetske stranice Korisnici iskazuju da se slažu s Općim uvjetima
      poslovanja i korištenja te pristaju na korištenje Internetske stranice
      www.kokolingo.hr u skladu s njima. Korisnike upućujemo da se prije same
      kupovine upoznaju s ovim Uvjetima te iste redovito provjeravaju kako bi
      bili upoznati sa svim pravima i obavezama.{' '}
    </p>

    <p>
      3. Pravo Korištenja Internetske stranice osobno je pravo Korisnika i ne
      može se ni na koji način prenositi na druge fizičke ili pravne osobe niti
      je bilo koji korisnik ovlašten prijavljivati druge fizičke ili pravne
      osobe.{' '}
    </p>

    <p>
      4. Ugovor o kupoprodaji proizvoda između Kupca i Prodavatelja zaključen je
      trenutkom narudžbe proizvoda.{' '}
    </p>

    <p>
      5. Kupac može biti samo punoljetna i poslovno sposobna osoba. Ugovor u ime
      i za račun maloljetnika i potpuno poslovno nesposobne osobe mogu
      zaključiti njihovi zakonski zastupnici ili skrbnici, a djelomično poslovno
      sposobne osobe ugovor mogu zaključiti samo uz suglasnost njihovog
      zakonskog zastupnika ili skrbnika. &nbsp;Za postupanje suprotno ovoj
      odredbi Prodavatelj ne snosi nikakvu odgovornost.
    </p>

    <p>
      6. Korisnik je osobno odgovoran za zaštitu povjerljivosti zaporki, na
      mjestima gdje one kao takve postoje. Korisnik je dužan dati točne, potpune
      i važeće osobne podatke prilikom ispunjavanja registracijskog obrasca.
      Postupanje suprotno tome, ovlašćuje Prodavatelja da uskrati takvom
      korisniku pristup ili ostvarenje svih ili dijela usluga koje nudi E-Glas.{' '}
    </p>

    <p>
      7. Korisnik je upoznat sa činjenicom da ponekad dolazi do prekida usluga,
      ili do događaja koji su izvan kontrole tvrtke E-Glas d.o.o. te prihvaća da
      tvrtka E-Glas d.o.o. nije odgovorna za bilo kakav gubitak podataka do
      kojeg može doći za vrijeme prijenosa informacija na Internetu. Korisnik se
      slaže i prihvaća da pristup Internetskim stranicama ponekad može biti u
      prekidu, privremeno nedostupan ili isključen.
    </p>

    <p>
      8. Tvrtka E-Glas d.o.o. zadržava pravo u bilo kojem trenutku i bez
      prethodne najave ako to smatra potrebnim, ukinuti ili izmijeniti sadržaj
      Općih uvjeta poslovanja, asortimana proizvoda, cijene proizvoda ili
      sadržaja Internetske stranice www.kokolingo.hr. Stoga su Korisnici dužni
      prije svakog korištenja Internetske stranice www.kokolingo.hr pregledati
      sadržaj Općih uvjeta poslovanja. Suprotno postupanje oslobađa Prodavatelja
      bilo kakve odgovornosti. Navedene promjene stupaju na snagu objavom na
      www.kokolingo.hr.{' '}
    </p>

    <p>
      9. Korištenje ove Internetske stranice www.kokolingo.hr isključivo je na
      odgovornost Korisnika. Prodavatelj se oslobađa od svake odgovornosti za
      štetu koja je mogla nastati prilikom posjete Internetskoj stranici{' '}
      <a href="http://www.kokolingo.hr" hrefLang="hr">
        www.kokolingo.hr
      </a>{' '}
      uslijed protupravnih radnji trećih osoba, računalnih virusa, prekidom u
      komunikacijskoj liniji, neovlaštenim pristupom, neprikladnim ponašanjem,
      nemarom i slično te drugih slučajeva za koje nije odgovoran Prodavatelj.
      Prodavatelj je oslobođen svake odgovornosti u slučaju nastanka okolnosti
      koje onemogućavaju korištenje www.kokolingo.hr internetske stranice.
      Uvjeti ovog poglavlja odnose se na cjelokupni sadržaj Internetske stranice
      www.kokolingo.hr.
    </p>

    <p>
      10. Prodavatelj se u potpunosti odriče bilo kakve odgovornosti za točnost
      i/ili potpunost svih informacija i sadržaja koje se nalaze na
      www.kokolingo.hr Internetskoj stranici. E-Glas d.o.o. zadržava pravo
      pogreške u opisu i slici proizvoda &nbsp;postavljene prema informacijama
      proizvođača. Svi sadržaji postavljeni na Internetskoj stranici postavljeni
      su kako bi kupcu olakšali izbor prilikom kupovine. Za nenamjerne greške ne
      odgovaramo.{' '}
    </p>

    <p>
      11. E-Glas sve materijale, fotografije i tekst postavlja na web stranicu u
      dobroj namjeri kako bi kupcu olakšali odabir prilikom kupovine. E-Glas ne
      jamči da fotografije proizvoda u potpunosti odgovaraju izgledu samog
      proizvoda. Za moguće nenamjerne greške kod opisa proizvoda ne odgovaramo.
      Moguće su različiti stvarnog proizvoda i fotografije proizvoda te opisanog
      proizvoda na ovim stranicama, ukoliko proizvođač izmijeni neku od
      karakteristika ili sadržaj proizvoda. Svi opisi se redovito i detaljno
      provjeravaju.
    </p>

    <p>
      12. E-Glas d.o.o. zadržava pravo na izmjenu ovih uvjeta i pravila. Sve
      promjene primijenit će se na korištenje www.kokolingo.hr stranica. Kupac
      snosi odgovornost za točnost i potpunost unesenih podataka prilikom
      kupnje.
    </p>

    <p>
      Usluge koje Vam pruža www.kokolingo.hr Internet prodavaonica ne uključuje
      troškove koje snosite koristeći računalnu opremu i usluge za pristup našim
      stranicama. E-Glas d.o.o. nije odgovoran za troškove telefona, Internet
      prometa ili bilo koje druge troškove do kojih može doći.
    </p>

    <p>
      13. Na uvjete poslovanja primjenjuje se Zakon o zaštiti potrošača
      (N.N.41/14, 110/15).
    </p>

    <p>14. Informacije o trgovačkom društvu:</p>

    <p>E-Glas d.o.o. </p>

    <p>Sjedište: Miroslava Krleže 4, 51000 Rijeka, Hrvatska</p>

    <p>Email: info@kokolingo.hr</p>

    <p>Tel: 051 670 379</p>

    <p>
      E-Glas d.o.o. za inteligentne računalne sustave i asistivnu tehnologiju
    </p>

    <p>IBAN HR4124840081105169596</p>

    <p>
      Društvo upisano u sudski registar Trgovačkog suda u Rijeci 13.07.2009.{' '}
    </p>

    <p>MBS: 040257941</p>

    <p>OIB: 01085855307</p>

    <p>Temeljni kapital: 20.000,00 kuna u cijelosti.</p>

    <p>Članovi uprave: Miroslav Vrankić.</p>

    <h2>Značenje izraza navedenih u ovim općim uvjetima</h2>

    <ul>
      <li>
        Prodavatelj &#8211; trgovačko društvo E-Glas d.o.o. Miroslava Krleže 4,
        51000 Rijeka, &nbsp;Hrvatska upisano u sudski registar Trgovačkog suda u
        Rijeci rješenjem od 13.07.2009.g. OIB: 01085855307
      </li>
      <li>www.kokolingo.hr &#8211; web stranica u vlasništvu Prodavatelja</li>
      <li>
        Kupac &#8211; svaka osoba koja je nakon pregleda i odabira proizvoda ili
        usluge izvršila registraciju svojih podataka te naručila proizvod ili
        uslugu.
      </li>
      <li>
        Korisnik &#8220;E-Glas&#8221; – svaka osoba koja web stranicu
        „www.kokolingo.hr&#8221; koristi u svrhu ostvarivanja kupnje proizvoda
        oglašenih na istoj ili dobivanja informacija o pojedinom proizvodu
      </li>
      <li>
        Korištenje „www.kokolingo.hr &#8221; – pristup stranici www.kokolingo.hr
        radi dobivanja informacija o sadržaju iste i/ili ostvarivanja web
        trgovine
      </li>
      <li>
        Online kupnja ili web trgovina – ostvarivanje kupnje proizvoda putem
        „www.kokolingo.hr&#8221;
      </li>
      <li>
        Proizvodi – svi proizvodi koji su istaknuti na „www.kokolingo.hr
        &#8220;, a koje je moguće kupiti putem web trgovine.
      </li>
    </ul>

    <h2>Načini plaćanja</h2>

    <p>
      Plaćanje na internetskoj stranici www.kokolingo.hr vrši se u kunama.
      Cijene su maloprodajne, izražene u kunama i u njima je sadržan pripadajući
      PDV. &nbsp;Cijena svakog proizvoda određena je za svaki proizvod
      pojedinačno. Proces upisivanja cijene na stranicu visoko je kontrolirani
      proces no unos cijena zahtjeva ljudski faktor te podrazumijeva mogućnost
      nastanka greške. Sve navedene cijene vrijede za kupovinu online, neovisno
      o eventualnoj cijeni za isti proizvod u trgovini.
    </p>

    <p>
      Prodavatelj je ovlašten mijenjati cijene bez prethodne obavijesti kao što
      je ovlašten bez prethodne obavijesti mijenjati cijene isključivo za web
      shop. Također, Prodavatelj je ovlašten bez prethodne obavijesti u svakom
      trenutku odrediti popust, dnevne ili tjedne akcije, akcije za pojedini
      proizvod, grupu proizvoda i/ili za sve proizvode. Navedene pogodnosti mogu
      biti isključive za web kupovinu što će kupcu biti naznačeno prije same
      kupovine.
    </p>

    <p>
      Akcije i popusti se međusobno isključuju i ne mogu se zbrajati osim ako
      izričito nije navedeno suprotno.{' '}
    </p>

    <p>
      Neovisno od prethodne točke ovih Općih uvjeta, sva naručena roba biti će
      isporučena po cijenama koje su vrijedile pri izradi ponude, ukoliko rok
      trajanja ponude nije istekao u trenutku plaćanja iste.
    </p>

    <h2>Web trgovina – sklapanje i raskid ugovora o prodaji</h2>

    <ul>
      <li>
        Online kupnja može se ostvariti samo ukoliko se kupac registrira na za
        to predviđenom mjestu na „ www.kokolingo.hr &#8220;. Prilikom
        ispunjavanja registracijskog obrasca kupac je dužan dati točne, važeće i
        potpune osobne podatke. Ovi Uvjeti kao i pojedini uvjeti prodaje
        naznačeni uz informacije o određenim proizvodima predstavljaju ponudu
        E-Glas za sklapanje Ugovora te korisnik kao kupac svojim registracijom,
        zaključivanjem narudžbe ili na drugi način utvrđen ovim Uvjetima iste
        prihvaća čime se smatra da je zaključen ugovor između kupca i E-Glasa, a
        prema uvjetima prodaje navedenim u ovim Uvjetima.
      </li>
      <li>
        Predmet i komercijalna svrha Ugovora je kupovina odabranog proizvoda ili
        usluge putem &#8220;www.kokolingo.hr” web trgovine uz plaćanje
        odgovarajuće naknade – cijene toga proizvoda ili usluge. Ugovor se
        zaključuje sredstvima daljinske komunikacije (Ugovor sklopljen na
        daljinu) i to putem: internet narudžbe-web trgovina, tiskanih
        promidžbenih poruka s narudžbenicom, telefona s ljudskim posredovanjem.
      </li>
      <li>
        Kupac je ovlašten izabrati mogućnost da ga Prodavatelj informira o novim
        proizvodima, proizvodima na akciji sl. (newsletter). Nakon uspješno
        obavljene registracije (pravilno popunjeni svi traženi podaci) na e-mail
        Kupca biti će dostavljena obavijest o uspješnoj registraciji.
      </li>
      <li>
        Proizvodi koje je moguće kupiti oglašeni su na „www.kokolingo.hr &#8221;
        a uz svaki proizvod navedeni su podaci o specifikaciji proizvoda,
        cijeni. Cijena i uvjeti dostave navedeni su pod &#8220;Načini dostave”.
      </li>
      <li>
        Odabir željenog proizvoda vrši se spremanjem u „košaricu&#8221; klikom
        na link „Dodaj u košaricu&#8221;. Ukoliko Kupac izvrši kupnju proizvoda
        koji je, u međuvremenu, prodan tada će Prodavatelj kontaktirati kupca
        radi dogovora o daljnjem postupanju (povrat uplaćenog iznosa, kupnja
        drugog proizvoda ili isporuka proizvoda iste vrste kada ga Prodavatelj
        pribavi od svojih dobavljača). Prodavatelj, osim netom navedene obveze,
        ne odgovara Kupcu u opisanom slučaju po bilo kojoj osnovi.
      </li>
      <li>
        U „košarici&#8221; se nalaze svi proizvodi koje je Kupac odabrao za
        kupnju zajedno sa cijenom proizvoda i cijenom dostave te ukupnom cijenom
        (uključen PDV). Ukoliko Kupac želi nastaviti sa kupnjom odabire link
        „Plaćanje&#8221;, a sustav ga dalje automatski vodi do stranice
        “Checkout&#8221; na kojoj je potrebno potvrditi podatke, adrese dostave,
        potvrditi ili eventualno izmijeniti dostavu računa i odabir načina
        plaćanja (virmansko plaćanje ili plaćanje kreditnom karticom). Detalji
        računa se mogu promijeniti u odnosu da li račun prima fizička osoba ili
        pravna. Ako račun prima pravna osoba potrebno je da kupac unese sve
        tražene podatke.
      </li>
      <li>
        Po izvršenju prethodno navedenih koraka web trgovine i odabirom
        “Narudžba&#8221; na stranici “Checkout&#8221;, sustav automatski vodi
        Kupca na sljedeću stranicu na kojoj se nalaze podaci o broju narudžbe,
        plaćanju kao i obavijest o slanju potvrdnog maila koji sadrži ponudu
        prodaje odabranih proizvoda te obavijest o e-mail adresi i besplatnom
        broju telefona putem kojih Kupac može dobiti sve potrebne informacije.
        Ako je kupac odabrao kartično plaćanje klik na “Narudžba&#8221; vodi ga
        na unos podataka vezanih za plaćanje karticom. Također može odabrati i
        plaćanje na rate beskamatno ukoliko je to plaćanje moguće sa karticom
        kojom plaća.
      </li>
    </ul>

    <h2>Naručene proizvode možete platiti na sljedeće načine</h2>

    <ul>
      <li>Putem virmana i opće uplatnice</li>
      <li>Karticama Maestro, MasterCard, Visa i American Express</li>
    </ul>

    <figure className="wp-block-image">
      <img
        src="https://lh3.googleusercontent.com/3BzotnjO04p320TNoGEpGzjjcYnK1KCJewMUBx9wh7rAYsMMb9ot9I-RIPJTNcL4KuKv_r26W7jYQ5add3bzS0WFmFdO8KHs9ZLgync8LtaKsXk2FfcL_FTL6w1BCbZeXhopsz3F"
        alt=""
      />
    </figure>

    <figure className="wp-block-image">
      <img
        src="https://lh6.googleusercontent.com/nuSQ8E93ycxSZs1K_wr_OiFqAWfNJwDoSrHiS-IQ7DUy0oW7DhaFv2MtPKxzSrDSEsCb0xhjbK7NKikGTAhl72Bw9h6GCapYD1vvDqMI-KBQnM3T2F7RLEZkHXCvh3TxxYvD-bNZ"
        alt=""
      />
    </figure>

    <figure className="wp-block-image">
      <img
        src="https://lh6.googleusercontent.com/63ofSej-d_yRnxhYEX1ua-VNk1JPK--oF8ogiP0sBNrhe6j4nfj5x5yfSaQiyOCt8XNfOwnpENxpG89MwZwlYhi7G0PmOo4vq1py3i5IVR5LGr8dSc5jS0YIBNVZaqBLbOS7Mtp7"
        alt=""
      />
    </figure>

    <figure className="wp-block-image">
      <img
        src="https://lh6.googleusercontent.com/ALk67yURILHvY_CK2T5kI1CE52bbDEiOHptCJCC2YANXzpOnP8mQsdC7Kk4stE8VZfxsK-Pt2tomXPZkznRS4Z7Rp2wDpiPCYxp84Y-9aQ3-vl3CrxKVN52Uxm3GZczmMAFaBrjb"
        alt=""
      />
    </figure>

    <h3>1. Plaćanje putem virmana ili opće uplatnice</h3>

    <p>
      Ako ste odabrali plaćanje virmanom ili općom uplatnicom na vašu e-mail
      adresu bit će poslana potvrda Vaše narudžbe. Djelatnici E-Glas Internet
      trgovine kontaktirat će Vas po potrebi.{' '}
    </p>

    <p>
      Ako se plaćanje proizvoda vrši putem opće uplatnice, korisnik na uplatnici
      mora ispuniti sljedeće podatke:{' '}
    </p>

    <ul>
      <li>Uplata na žiro račun E-Glas d.o.o.: HR4124840081105169596</li>
      <li>Model: HR00</li>
      <li>Poziv na broj: broj narudžbe/ponude</li>
      <li>Primatelj: E-Glas d.o.o., Miroslava Krleže 4, 51000 Rijeka</li>
    </ul>

    <p>
      Po zaprimljenoj uplati poslat ćemo Vam naručene proizvode najkasnije 30
      dana od dana zaprimanja uplate. Naručeni proizvodi se dostavljaju kupcu u
      skladu s uvjetima dostave.
    </p>

    <p>
      Ako se plaćanje proizvoda vrši putem virmana, korisnik na uplatnici mora
      ispuniti sljedeće podatke:{' '}
    </p>

    <ul>
      <li>Uplata na žiro račun E-Glas d.o.o.: HR4124840081105169596</li>
      <li>Model: HR00</li>
      <li>Poziv na broj: broj narudžbe/ponude</li>
      <li>Primatelj: E-Glas d.o.o., Miroslava Krleže 4, 51000 Rijeka</li>
      <li>Matični broj E-Glas d.o.o.: 02536307</li>
    </ul>

    <p>
      Po zaprimljenoj uplati poslat ćemo Vam naručene proizvode najkasnije 30
      dana od dana zaprimanja uplate. Naručeni proizvodi se dostavljaju kupcu u
      skladu s uvjetima dostave.
    </p>

    <h3>2. Karticama Maestro, MasterCard, Visa, American Express</h3>

    <p>
      Ako ste odabrali plaćanje kreditnom karticom, na sigurnoj stranici
      CorvusPay Payments odaberete vrstu kreditne kartice kojom plaćate
      (American Express, MasterCard, Visa ili Diners) te upišete podatke o
      vlasniku kreditne kartice i ostale potrebne podatke.
    </p>

    <ul>
      <li>
        Jednokratno plaćanje American Express, Visa, MasterCard i Maestro
        karticama
      </li>
      <li>Obročno plaćanje od 2 do 12 rata American Express</li>
      <li>
        Obročno plaćanje Visa i MasterCard karticama PBZ-a do 6 ili 12 rata.
      </li>
    </ul>

    <h2>Dostava</h2>

    <h3>1. Besplatna dostava</h3>

    <p>
      Dostava za sve narudžbe iznad 1000 kuna kupljene u E-Glas internetskoj
      trgovini je besplatna za područje cijele Hrvatske. Trošak dostave za
      narudžbe manje od 1000 kuna iznosi 40 kuna.{' '}
    </p>

    <p>
      Proizvodi koji su plaćeni sukladno dostavljenoj ponudi bit će isporučeni
      Kupcu najkasnije u roku od 30 dana, (osim otoka, ovisno o voznom redu
      trajekta i Dubrovačke regije).
    </p>

    <p>
      Ako želite dogovoriti odgovarajući termin dostave, molimo vas da to
      napišete pod NAPOMENA kako bi mogli vašu želju mogli prenijeti dostavnoj
      službi.{' '}
    </p>

    <p>
      Robu je moguće preuzeti i u našoj poslovnici, a jedini uvjet kod osobnog
      preuzimanja je prethodna najava na telefon ili dogovor putem online
      obrasca.{' '}
    </p>

    <h3>2. Proces dostave</h3>

    <ul>
      <li>
        Dostavu vrši Prodavatelj (putem svojih djelatnika) ili angažiranjem
        renomirane dostavne (kurirske) službe.
      </li>
      <li>
        Dostava se vrši putem prijevozničkih tvrtki koje obavljaju usluge
        transporta.
      </li>
      <li>
        Proizvodi bit će zapakirani tako da se uobičajenom manipulacijom u
        transportu ne mogu oštetiti.
      </li>
      <li>
        Kupac je dužan prilikom preuzimanja proizvoda provjeriti eventualna
        oštećenja i odmah ih reklamirati dostavnom radniku koji je robu
        dostavio, odnosno odbiti preuzeti pošiljku na kojoj su vidljiva vanjska
        oštećenja. Kupac je obavezan prilikom preuzimanja robe potpisati
        otpremnicu ili dostavnicu te je dostavna služba uzima kao potvrdu o
        preuzimanju.
      </li>
      <li>
        U slučajevima kada je Kupcu isporučen proizvod različit od onog kojeg je
        kupio, isti ima pravo na isporuku naručenog proizvoda, a ukoliko to nije
        moguće Kupac ima pravo na povrat novca u iznosu plaćene cijene
        proizvoda, cijene dostave i naknadu troškova povrata proizvoda te je
        dužan vratiti krivo dostavljen proizvod.
      </li>
      <li>
        U slučaju da kupljeni proizvod Prodavatelj nije u mogućnosti isporučiti
        u navedenom roku, o tome će obavijestiti Kupca radi dogovora o novom
        roku isporuke. U ovom slučaju Kupac ima pravo i na raskid Ugovora.
      </li>
      <li>
        Prilikom dostave, uz kupljeni proizvod, Kupac dobiva sve isprave koje
        prate proizvod (korisnički priručnik, jamstveni list), račun, te potvrdu
        o primitku pošiljke koju je obvezan potpisati. Potpisom na potvrdi o
        primitku pošiljke smatra se da je proizvod preuzet bez vanjskih
        vidljivih oštećenja.
      </li>
      <li>
        Roba je osigurana od gubitka u dostavi. Kupac je obavezan prilikom
        preuzimanja robe potpisati otpremnicu ili dostavnicu te je dostavna
        služba uzima kao potvrdu o preuzimanju.
      </li>
      <li>
        Cijene dostave istaknute su na „www.kokolingo.hr&#8221; i u njima je
        sadržan pripadajući PDV.
      </li>
    </ul>

    <h3>3. Status narudžbe</h3>

    <p>
      Prilikom obrade pošiljke Kupac dobiva obavijest na mail o statusu njegove
      pošiljke.
    </p>

    <h2>Povrati</h2>

    <h3>1. Povrat</h3>

    <ul>
      <li>
        Prije samog sklapanja Ugovora, korisnik ima mogućnost pregledati Uvjete
        korištenja www.kokolingo.hr koji su navedeni unutar na dnu stranice
        www.kokolingo.hr/trgovina (obavijest iz članka 57. st. 1. Zakona o
        zaštiti potrošača (Narodne novine, br. 41/2014), te obavijest o pravu
        korisnika na jednostrani raskid Ugovora s obrascem za jednostrani raskid
        Ugovora iz članka 61. st. 1. i 2. Zakona o zaštiti potrošača.)
      </li>
      <li>
        Sukladno članku 77, stavak 1. Zakona o zaštiti potrošača, korisnik može
        jednostrano raskinuti Ugovor u roku od<strong> </strong>14 (četrnaest)
        dana<strong> </strong>od predaje robe u posjed Kupcu bez navođenja
        razloga.
      </li>
      <li>
        Korisnik nema pravo na jednostrani raskid Ugovora ako mu je usluga u
        potpunosti ispunjena, a ispunjenje je započelo uz njegov izričit
        prethodni pristanak te uz njegovu potvrdu da je upoznat s činjenicom da
        će izgubiti pravo na jednostrani raskid Ugovora ako mu usluga bude
        potpuno ispunjena.
      </li>
      <li>
        Sukladno članku 77., stavak 5. Zakona o zaštiti potrošača, korisnik je
        odgovoran za svako umanjenje vrijednosti robe koje je rezultat rukovanja
        robom, osim onog koje je bilo potrebno za utvrđivanje prirode, obilježja
        i funkcionalnosti robe.
      </li>
      <li>
        Vraćeni proizvod mora biti u originalnom pakiranju i sa deklaracijom, te
        pripadajućim sadržajem i računom, uputama za korištenje i jamstvenim
        listom. E-Glas d.o.o. utvrđuje uvjete povrata &#8211; postotke umanjenja
        iznosa povrata kupcu uslijed umanjenja vrijednosti proizvoda.
        <br />
        Potrošač snosi trošak povrata robe.
      </li>
    </ul>

    <h3>2. Pravo na povrat</h3>

    <p>Nedostaci za koje prodavatelj ne odgovara:</p>

    <ul>
      <li>
        Prodavatelj ne odgovara za nedostatke ako su u trenutku sklapanja
        ugovora bili poznati kupcu ili mu nisu mogli ostati nepoznati.
      </li>
      <li>
        Smatra se da nisu mogli ostati nepoznati kupcu oni nedostaci koje bi
        brižljiva osoba s prosječnim znanjem i iskustvom osobe istog zanimanja i
        struke kao kupac mogla lako opaziti pri uobičajenom pregledu stvari.
      </li>
      <li>
        Odredba stavka 2. ovoga članka ne primjenjuje se na ugovore koje fizička
        osoba kao kupac sklapa izvan svoje gospodarske ili profesionalne
        djelatnosti s fizičkom ili pravnom osobom koja kao prodavatelj djeluje u
        okviru svoje gospodarske ili profesionalne djelatnosti (potrošački
        ugovor).
      </li>
      <li>
        Ali prodavatelj odgovara i za nedostatke koje je kupac mogao lako
        opaziti ako je izjavio da stvar nema nikakve nedostat­ke ili da stvar
        ima određena svojstva ili odlike.
      </li>
    </ul>

    <h3>Pregled stvari i vidljivi nedostaci:</h3>

    <ul>
      <li>
        Kupac je dužan primljenu stvar na uobičajeni način pregledati ili je
        dati na pregled, čim je to prema redovitom tijeku stvari moguće, i o
        vidljivim nedostacima obavijestiti prodavatelja u roku od osam dana, a
        kod trgovačkog ugovora bez odgađanja, inače gubi pravo koje mu po toj
        osnovi pripada.
      </li>
      <li>
        Kad je pregled izvršen u nazočnosti obiju strana, kupac je dužan svoje
        primjedbe zbog vidljivih nedostataka priopćiti prodavatelju odmah, inače
        gubi pravo koje mu po toj osnovi pripada.
      </li>
      <li>
        Ako je kupac otpremio stvar dalje bez pretovara, a prodavatelju je pri
        sklapanju ugovora bila poznata ili morala biti poznata mogućnost takve
        daljnje otpreme, pregled stvari može biti odgođen do njezina prispijeća
        u novo odredišno mjesto, i u tom slučaju kupac je dužan prodavatelja
        obavijestiti o nedostacima čim je po redovitom tijeku stvari mogao za
        njih doznati od svojih klijenata.
      </li>
      <li>
        Kod potrošačkih ugovora potrošač kao kupac nije obvezan pregledati stvar
        niti je dati na pregled, ali je obvezan obavijestiti prodavatelja o
        postojanju vidljivih nedostataka u roku od dva mjeseca od dana kad je
        otkrio nedostatak.
      </li>
    </ul>

    <h3>Skriveni nedostaci:</h3>

    <ul>
      <li>
        Kad se nakon primitka stvari od strane kupca pokaže da stvar ima neki
        nedostatak koji se nije mogao otkriti uobičajenim pregledom prilikom
        preuzimanja stvari, kupac je dužan, pod prijetnjom gubitka prava, o tom
        nedostatku obavijestiti prodavatelja u roku od dva mjeseca računajući od
        dana kad je nedostatak otkrio, a kod trgovačkog ugovora – bez odgađanja.
      </li>
      <li>
        Prodavatelj ne odgovara za nedostatke koji se pokažu pošto proteknu
        dvije godine od predaje stvari, a kod trgovačkog ugovora šest mjeseci.
      </li>
      <li>
        Kod prodaje rabljenih stvari ugovorne strane mogu ugovoriti rok od jedne
        godine, a kod trgovačkih ugovora i kraći rok.
      </li>
      <li>
        Ugovorom se mogu produljiti rokovi iz stavka 2. i 3. ovoga članka.
      </li>
    </ul>

    <h3>3. Jednostrani raskid Ugovora</h3>

    <ul>
      <li>
        Standardni informacijski obrazac za jednostrani raskid ugovora možete
        preuzeti{' '}
        <a href="https://www.eglas.hr/wp-content/uploads/obrazac-za-jednostrani-raskid-ugovora-eglas.pdf?x92258">
          ovdje
        </a>
        .
      </li>
      <li>
        Da bi Korisnik mogao ostvariti pravo na jednostrani raskid Ugovora, mora
        obavijestiti E-Glas d.o.o. o svojoj odluci o jednostranom raskidu
        Ugovora prije isteka roka.
      </li>
      <li>
        Izjavu je potrebno poslati poštom na E-Glas d.o.o. na Miroslava Krleže
        4, 51000 Rijeka, Hrvatska ili elektroničkom poštom na info@kokolingo.hr
      </li>
      <li>
        U Izjavi je potrebno navesti: broj računa, datum računa, ime i prezime,
        adresu, broj telefona, telefaksa ili adresu elektroničke pošte.
      </li>
      <li>
        Ako korisnik jednostrano raskine Ugovor, izvršit će mu se povrat novca
        koji je od njega primljen, uključujući i troškove isporuke, bez
        odgađanja, a najkasnije u roku od 14 (četrnaest) dana od dana kada
        E-Glas zaprimi korisnikovu odluku o jednostranom raskidu Ugovora, osim
        ukoliko je korisnik odabrao drugu vrstu isporuke, a koja nije
        najjeftinija standardna isporuka koja je ponuđena.
      </li>
      <li>
        Povrat novca bit će izvršen na isti način na koji je korisnik izvršio
        uplatu. U slučaju da korisnik pristaje na drugi način povrata plaćenog
        iznosa, ne snosi nikakve troškove u odnosu na povrat. Povrat novca
        E-Glas može izvršiti tek nakon što mu roba bude vraćena ili nakon što mu
        bude dostavljen dokaz da je roba poslana nazad.
      </li>
      <li>
        Korisnik je dužan robu predati ili je poslati na adresu E-Glas d.o.o.
        Miroslava Krleže 4, 51000 Rijeka, Hrvatska bez nepotrebnog odgađanja, a
        u svakom slučaju najkasnije u roku od 14 (četrnaest) dana od dana kada
        je E-Glasu, uputio svoju odluku o jednostranom raskidu Ugovora.
      </li>
      <li>
        Izravne troškove povrata robe korisnik mora snositi sam. Korisnik se
        moli da prije raskida Ugovora nazove na broj telefona 051/670 379 ili se
        javi na info@kokolingo.hr kako bi mu bio objašnjen postupak vraćanja
        robe.
      </li>
    </ul>

    <h3>4. Reklamacija</h3>

    <p>
      Ako ustanovite prilikom prve uporabe da je proizvod kojeg ste dobili
      neispravan ili da niste njime zadovoljni, molimo vas da nas kontaktirate i
      slijedite navedene korake.{' '}
    </p>

    <ul>
      <li>
        Pošaljite nam informacije s brojem narudžbe, brojem računa ili vašim
        korisničkim imenom i opisom reklamacije e-mailom na info@kokolingo.hr,
        telefonski broj 051/670 379 ili na društvenim mrežama.
      </li>
      <li>
        Poslat ćemo Vam potvrdu primitka reklamacije te temeljem vašeg opisa
        informaciju može li se proizvod zamijeniti za novi, može li se za
        proizvod dobiti povrat novca ili je proizvod potrebno poslati u
        ovlašteni servis.
      </li>
      <li>
        U slučajevima kad se proizvod može zamijeniti za novi i kad se za
        proizvod može vratiti novac, na Vašu adresu šaljemo dostavnu službu koja
        prikuplja reklamirani proizvod i vraća na naše skladište. &nbsp;
      </li>
      <li>
        Kad proizvod stigne na skladište, šaljemo Vam obavijest o povratu
        novaca/slanju zamjenskog proizvoda. Povrat novca/zamjena za novi
        proizvod bit će odrađena unutar 2-3 dana, a najkasnije nakon 15 dana. U
        suprotnom nas kontaktirajte telefonski na 051/670 379 ili e-mailom na
        info@kokolingo.hr
      </li>
      <li>
        U slučaju da je proizvod potrebno poslati u ovlašteni servis, poslat
        ćemo Vam sve podatke &nbsp;ovlaštenog servisa. Naš ovlašteni servis je
        tvrtka E- Glas d.o.o.
      </li>
      <li>
        Ako je pošiljka oštećena u transportu, takva su oštećenja vidljiva pri
        preuzimanju pošiljke te vas u tom slučaju molimo da pošiljku ne
        preuzimate. Molimo vas da nas kontaktirate kako bismo u što kraćem roku
        provjerili stanje pošiljke i poslali novu.
      </li>
    </ul>

    <p>
      Vjerujemo da ćemo na obostrano zadovoljstvo riješiti Vašu reklamaciju.
      <br />
      <br />
      Ako kupac odabere opciju downloada proizvoda te kupac ne bude u mogućnosti
      obaviti download zbog tehničkih problema uzrokovanih na serveru
      prodavatelja prodavatelj se obvezuje poslati elektronsku poštu sa novom
      mogućnosti za download za što kupac ne snosi nikakve troškove.
    </p>

    <p>
      U slučaju da kupac niti tada ne bude u stanju obaviti download koji je
      uzrokovan zbog tehničkih problema na server prodavatelja, kupac je dužan o
      tome kontaktirati prodajnu službu prodavatelja koja će ponuditi adekvatno
      rješenje koje u sebi neće sadržavati nikakvu naknadu i daljnje troškove od
      strane kupca. Kupac nema pravo tražiti povrat novca u ovoj situaciji.
    </p>

    <p>
      U slučaju da kupac ne može downloadati proizvod,a tehnički problemi nisu
      vezani za prodavatelja, već su uzrokovani od strane kupca, operatera ili
      treće osobe prodavatelj ne snosi nikakvu odgovornost I nije dužan kupcu
      omogućiti download ili isporuku robe bez naknade.
    </p>

    <h3>5. Jamstvo</h3>

    <p>
      Period jamstva naznačen je kod svakog proizvoda ili usluge. Ukoliko period
      jamstva nije naveden proizvod ili usluga ne podliježe jamstvu.{' '}
    </p>

    <p>
      Kupac kao fizička osoba kod svakog proizvoda ili usluge ima pravo na
      Zakonsko jamstvo koje vrijedi dvije godine te počinje teći od dana kada je
      proizvod dostavljen.{' '}
    </p>

    <p>
      Zadržavamo pravo na popravljanje proizvoda u slučaju kvara tijekom
      garancijskog razdoblja.
    </p>

    <h2>Pravila privatnosti</h2>

    <p>
      Ova pravila privatnosti objašnjava kako E-Glas d.o.o. (dalje u
      tekstu:&#8221;E-Glas&#8221;) obrađuje vaše osobne podatke. E-Glas je
      usmjeren na zaštitu vaših osobnih podataka i njihovu obradu u skladu sa
      Općom uredbom o zaštiti podataka i Zakonom o provedbi Opće uredbe o
      zaštiti podataka. Pročitajte ova pravila pažljivo kako biste razumjeli
      zašto i kako prikupljamo vaše osobne podatke i kako će se oni koristiti.
    </p>

    <p>
      E-Glas je voditelj obrade Vaših osobnih podataka. Imamo službenika za
      zaštitu osobnih podataka i možete nas kontaktirati putem e-maila:
      info@kokolingo.hr ili na adresu Miroslava Krleže 4, 51000 Rijeka.
    </p>

    <h3>Svrhe u koje obrađujemo osobne podatke</h3>

    <p>
      Vaše osobne podatke obrađujemo jer nam zakonski propisi nalažu određena
      postupanja, ili jer je obrada nužna za izvršavanje ugovora, ili kako bi se
      poduzele radnje prije sklapanja ugovora ili temeljem naših legitimnih
      interesa, osim kada su od tih interesa jači interesi ili temeljna prava i
      slobode pojedinaca koji zahtijevaju zaštitu osobnih podataka. Ukoliko
      osobne podatke ne možemo obrađivati temeljem navedenih pravnih osnova
      zatražit ćemo vašu privolu. Ako se obrada temelji na vašoj privoli imate
      pravo u bilo kojem trenutku povući privolu. O povlačenju privole potrebno
      je obavijestiti voditelja obrade na e-mail info@kokolingo.hr ili na adresu
      Miroslava Krleže 4, 51000 Rijeka. Takvo povlačenje neće utjecati na
      zakonitost obrade na temelju privole prije njezina povlačenja.{' '}
    </p>

    <p>
      Vaše osobne podatke obrađujemo kada je to potrebno za sklapanje i
      ispunjenje prava i obveza koje proizlaze iz ugovora o kupoprodaji, u
      marketinške svrhe, u svrhe unaprjeđenja našeg poslovanja i Vašeg
      korisničkog iskustva (npr. kada kupite ili naručite proizvode i/ili usluge
      od nas, kada kontaktirate našu službu za korisnike, kada se prijavite na
      naš newsletter, kada ispunite anketu o našim proizvodima i uslugama itd.).{' '}
    </p>

    <p>
      Osobne podatke uglavnom prikupljamo izravno od vas, kada naručite određene
      proizvode ili usluge ili ispunite određeni obrazac ili kada podatke
      prikupljamo nadzorom (npr. putem video nadzora).{' '}
    </p>

    <h3>Kategorije osobnih podataka</h3>

    <p>
      Kod sklapanja ugovora o kupoprodaji potrebni su nam podaci kao što su ime,
      prezime, adresa, radi ispunjenja naše obveze isporuke proizvoda i usluga.
      Ponekad Vas tražimo i dodatne osobne podatke kao što su: e-mail adresa,
      ime i prezime. Prikupljamo osobne podatke koje nam pružite prilikom
      popunjavanja online obrazaca ili obrazaca u našim poslovnicama.
    </p>

    <p>
      Kada podatke prikupljamo putem nadzora (npr. preko video nadzora), to
      radimo u skladu sa zakonskim odredbama ili u opravdanim slučajevima kako
      bi osigurali osobnu sigurnost vas i E-Glas suradnika, zaštitili vašu i
      imovinu E-Glasa te kako bi povećali sigurnost naših IT sustava.
    </p>

    <h3>Vaša prava</h3>

    <p>Vaša prava su slijedeća:</p>

    <p>
      <strong>Pravo na pristup:</strong>
    </p>

    <ul>
      <li>
        imate pravo dobiti od nas potvrdu o tome obrađuju li se vaši osobni
        podaci, te ako se takvi osobni podaci obrađuju, pristup osobnim
        podacima. Informacije o pristupu uključuju &#8211; između ostalog
        &#8211; svrhe obrade, kategorije osobnih podataka o kojima je riječ,
        primatelje ili kategorije primatelja kojima su osobni podaci bili ili će
        biti otkriveni. Međutim, to nije bezuvjetno pravo i interesi drugih
        pojedinaca mogu ograničiti vaše pravo pristupa.
      </li>
      <li>
        imate pravo dobiti kopiju osobnih podataka koji se obrađuju. Za daljnje
        kopije koje ste zatražili, možemo naplatiti razumnu naknadu na temelju
        administrativnih troškova.
      </li>
    </ul>

    <p>
      <strong>Pravo na ispravak:</strong>
    </p>

    <ul>
      <li>
        imate pravo ishoditi od nas ispravljanje vaših netočnih osobnih
        podataka. Uzimajući u obzir svrhe obrade imate pravo dopuniti nepotpune
        osobne podatke, među ostalim i davanjem dodatne izjave.
      </li>
    </ul>

    <p>
      <strong>Pravo na brisanje (&#8220;pravo na zaborav&#8221;):</strong>
    </p>

    <ul>
      <li>
        pod određenim uvjetima, imate pravo ishoditi od nas brisanje vaših
        osobnih podataka i mi smo obvezni izbrisati takve osobne podatke.
      </li>
    </ul>

    <p>
      <strong>Pravo na ograničenje obrade:</strong>
    </p>

    <ul>
      <li>
        pod određenim uvjetima, imate pravo ishoditi od nas ograničenje obrade
        vaših osobnih podataka. U tom će slučaju odgovarajući podaci biti
        označeni i mogu biti obrađeni samo u određene svrhe.
      </li>
    </ul>

    <p>
      <strong>Pravo na prigovor:</strong>
    </p>

    <ul>
      <li>
        pod određenim uvjetima, imate pravo na prigovor u bilo kojem trenutku na
        obradu vaših osobnih podataka, iz razloga koji se odnose na vašu
        konkretnu situaciju ili kada se osobni podaci obrađuju u cilju izravnog
        marketinga te možete tražiti od nas da više ne obrađujemo vaše osobne
        podatke.
      </li>
    </ul>

    <p>
      <strong>Pravo na prenosivost podataka:</strong>
    </p>

    <ul>
      <li>
        pod određenim uvjetima, imate pravo zaprimiti osobne podatke koji se
        odnose na vas, koje ste nam pružili u strukturiranom, uobičajeno
        upotrebljivom i strojno čitljivom formatu i imate pravo prenijeti te
        podatke drugom voditelju obrade, bez našeg ometanja.
      </li>
    </ul>

    <p>
      Ako želite saznati više ili želite koristiti jedan ili više od gore
      navedenih prava, slobodno kontaktirajte E-Glas na e-mail info@kokolingo.hr
      ili na adresu Miroslava Krleže 4, 51000 Rijeka.
    </p>

    <p>
      Ako niste zadovoljni kako smo obrađujemo Vaše osobne podatke, možete se
      obratiti Agenciji za zaštitu osobnih podataka.{' '}
    </p>

    <h3>Čuvanje osobnih podataka</h3>

    <p>
      Osobne podatke koje prikupimo o vama čuvamo u sigurnom okruženju. Vaši
      osobni podaci su zaštićeni od neovlaštenog pristupa, otkrivanja, uporabe,
      izmjena ili uništenja od strane bilo koje organizacije ili pojedinca.{' '}
    </p>

    <p>
      Prikupljeni podaci za gore navedene svrhe E-Glas bit će pohranjeni samo
      onoliko dugo koliko je to potrebno za gore navedene svrhe. Vaši se osobni
      podaci neće čuvati u obliku koji omogućuje da budete identificirani duže
      nego što E-Glas razumno smatra da je neophodno za ostvarenje svrhe za koju
      su prikupljenji ili obrađeni. E-Glas će određene osobne podatke čuvati u
      vremenskom periodu koji propisuje zakon odnosno propis koji obvezuje
      E-Glas na čuvanje podataka.
    </p>

    <p>
      Ako ste nam dali privolu, Vaše osobne podatke obrađivat ćemo do povlačenja
      privole. Ako izjavite osnovani prigovor na obradu osobnih podataka
      temeljem legitimnog interesa Vaše osobne podatke nećemo obrađivati u
      budućnosti.{' '}
    </p>

    <p>
      Ako je pokrenut sudski, upravni ili izvansudski postupak, osobni se podaci
      mogu pohraniti do kraja takvog postupka, uključujući i moguće razdoblje za
      izjavljivanje pravnih lijekova. &nbsp;E-Glas će određene osobne podatke
      čuvati u vremenskom periodu koji propisuje zakon odnosno propis koji
      obvezuje voditelja obrade na čuvanje podataka.
    </p>

    <h3>Primatelji osobnih podataka</h3>

    <p>
      Uz navedeno, vaše osobne informacije možemo dati našim pouzdanim
      partnerima koji održavaju naš IT sustav ili pružaju usluge u ime E-Glasa.
      Na primjer, za potrebe marketinga, financija, oglašavanje, obrade
      plaćanja, isporuke i druge usluge u i izvan trgovine. Ti davatelji usluga
      su pak dužni, prema relevantnim ugovorima, koristiti podatke koji su im
      povjereni samo u skladu s našim smjernicama i isključivo u svrhu koju smo
      strogo odredili. Također obvezujemo ih da adekvatno zaštitite vaše podatke
      i da ih smatraju poslovnom tajnom.
    </p>

    <p>
      U nekim slučajevima, naši partneri koji pružaju usluge u ime ili za E-Glas
      mogu vaše podatke obraditi izvan Europske unije. Međutim, ugovori koje
      sklapamo s takvim subjektima obvezuju ih da vašim podacima postupaju sa
      posebnim sigurnosnim mjerama u skladu s propisima koji su na snazi u
      Europskoj uniji.
    </p>

    <h3>Kolačići</h3>

    <p>
      Zadržat ćemo i procijeniti informacije o Vašem nedavnom posjetu našoj web
      stranici i kako ste koristili različite dijelove naše web stranice radi
      analitičkih ciljeva, odnosno kako bismo razumijeli na koji način korisnici
      koriste naše web stranice.{' '}
    </p>

    <p>
      Kako bi održavali vašu web stranicu i osigurali da njezine funkcionalnost
      budu na očekivanoj razini, E-Glas koristi tehnologiju poznatu kao
      &#8220;kolačići&#8221;. Kolačići su male datoteke koje šaljemo na vaše
      računalo i možemo im kasnije pristupiti. Oni mogu biti privremeni ili
      trajni. Zahvaljujući kolačićima možete bez poteškoća pretraživati naše
      stranice te prikazivati rezultate koji su relevantni za vas. Kolačići nam
      pokazuju ono što zanima vas i druge posjetitelje naše web stranice, što
      nam pomaže da ju poboljšamo.
    </p>

    <p>Sigurnost plaćanja </p>

    <h3>Izjava o sigurnosti online plaćanja</h3>

    <p>
      Pri plaćanju na našoj web trgovini koristite CorvusPay – napredni sustav
      za siguran prihvat platnih kartica putem interneta.
    </p>

    <p>
      CorvusPay osigurava potpunu tajnost Vaših kartičnih podataka već od
      trenutka kada ih upišete u CorvusPay platni formular. Platni podaci
      prosljeđuju se šifrirano od Vašeg web preglednika do banke koja je izdala
      Vašu karticu. Naša trgovina nikada ne dolazi u kontakt s cjelovitim
      podacima o Vašoj platnoj kartici. Također, podaci su nedostupni čak i
      djelatnicima CorvusPay sustava. Izolirana jezgra samostalno prenosi i
      upravlja osjetljivim podacima, čuvajući ih pri tom potpuno sigurnima.
    </p>

    <p>
      Formular za upis platnih podataka osiguran je SSL transportnom šifrom
      najveće pouzdanosti. Svi skladišteni podaci dodatno su zaštićeni
      šifriranjem, korištenjem kriptografskog uređaja certificiranog prema FIPS
      140-2 Level 3 standardu. CorvusPay zadovoljava sve zahtjeve vezane uz
      sigurnost on-line plaćanja propisane od strane vodećih kartičnih brandova,
      odnosno posluje sukladno normi – PCI DSS Level 1 – najviši sigurnosni
      standard industrije platnih kartica. Pri plaćanju karticama uvrštenim u
      3-D Secure program Vaša banka uz valjanost same kartice dodatno potvrđuje
      i Vaš identitet pomoću tokena ili lozinke.
    </p>

    <p>
      Corvus Info sve prikupljene informacije smatra bankovnom tajnom i tretira
      ih u skladu s tim. Informacije se koriste isključivo u svrhe za koje su
      namijenjene. Vaši osjetljivi podaci u potpunosti su sigurni, a njihova
      privatnost zajamčena je najmodernijim zaštitnim mehanizmima. Prikupljaju
      se samo podaci nužni za obavljanje posla sukladno propisanim zahtjevnim
      procedurama za on-line plaćanje.
    </p>

    <p>
      Sigurnosne kontrole i operativne procedure primijenjene na našu
      infrastrukturu osiguravaju trenutnu pouzdanost CorvusPay sustava. Uz to
      održavanjem stroge kontrole pristupa, redovitim praćenjem sigurnosti i
      dubinskim provjerama za sprječavanje ranjivosti mreže te planskim
      provođenjem odredbi o informacijskoj sigurnosti trajno održavaju i
      unaprjeđuju stupanj sigurnosti sustava zaštitom Vaših kartičnih podataka.
      Sigurnosne kontrole i operativne procedure primijenjene na našu
      infrastrukturu osiguravaju trenutnu pouzdanost CorvusPay sustava. Uz to
      održavanjem stroge kontrole pristupa, redovitim praćenjem sigurnosti i
      dubinskim provjerama za sprječavanje ranjivosti mreže te planskim
      provođenjem odredbi o informacijskoj sigurnosti trajno održavaju i
      unaprjeđuju stupanj sigurnosti sustava zaštitom Vaših kartičnih podataka.
    </p>

    <h2>Pohvale i prigovori</h2>

    <h3>Pisani prigovor, odgovor, obavijesti</h3>

    <ul>
      <li>
        U skladu s člankom 10., Zakona o zaštiti potrošača u slučaju da ste iz
        bilo kojeg razloga nezadovoljni možete uputiti pisani prigovor
        www.kokolingo.hr internetskoj trgovini.
      </li>
      <li>
        E-Glas vam omogućuje podnošenje pisanog prigovora putem pošte, telefona
        051/670 379 ili putem maila info@kokolingo.hr.
      </li>
      <li>
        Radi što bržeg utvrđivanja konkretne narudžbe na koju imate prigovor, u
        prigovoru navedite broj narudžbe, broj računa ili svoje ime i prezime.
      </li>
      <li>
        E-Glas će vam potvrditi primitak prigovora pisanim putem bez odgađanja,
        a odgovor će Vam stići u roku od 15 (petnaest) dana od dana zaprimljenog
        prigovora.
      </li>
    </ul>

    <h2>Kontakti</h2>

    <ul>
      <li>
        Za sve informacije, pohvale, primjedbe, reklamacije kao i svake druge
        obavijesti Kupci se mogu obratiti Prodavatelju na adresu Miroslava
        Krleže 4, 51000 Rijeka, Hrvatska.
      </li>
      <li>Telefon: 051/670 379</li>
      <li>E-mail: info@kokolingo.hr</li>
    </ul>

    <h2>Platforma za online rješavanje sporova</h2>

    <p>
      Posebnom regulativom Europske unije, od 15.02.2016, diljem cijele EU
      sporove vezane uz online kupnje bit će moguće riješiti putem ODR
      platforme. Platforma za rješavanje sporova dostupna je na internetskoj
      stranici{' '}
      <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=HR">
        &nbsp;http://ec.europa.eu/consumers/odr/
      </a>
      .
    </p>

    <p>
      To znači da ako naiđete na problem tijekom online kupnje unutar EU odnosno
      sporovi koji se odnose na internetsko naručivanje najprije pokušate
      riješiti bez uključivanja sudova.{' '}
    </p>

    <p>
      Ovaj link potrošači mogu koristiti za prekogranične potrošačke sporove. Za
      potrošačke sporove u odnosu na trgovce iz Republike Hrvatske ta mogućnost
      ne postoji dok procedura ne bude podržana &nbsp;potrebnom zakonskom
      regulativom od strane ovlaštenih tijela RH.
    </p>

    <h3>Ostale odredbe</h3>

    <p>
      1. Prodavatelj daje pravo Kupcima koristiti sve usluge na
      „www.kokolingo.hr&#8221; samo za osobne potrebe, u nekomercijalne svrhe.
      Promjena sadržaja na „www.kokolingo.hr&#8221;, u bilo kojem njegovom
      obliku, zabranjena je kao i kopiranje, javno izvođenje i sl. Uporaba
      sadržaja www.kokolingo.hr&#8221; na drugim web stranicama je zabranjen.
    </p>

    <p>
      2. Prodavatelj zadržava pravo dati prava za objavljivanje bilo kojeg
      materijala, dijelova „www.kokolingo.hr&#8221; trećoj osobi samo u slučaju
      sklopljenog ugovora koji uređuje prava i obveze Prodavatelja i trećeg koji
      objavljuje informaciju.
    </p>

    <p>
      3. Pri kupnji bilo kojeg proizvoda, koji je predmet autorskih prava ili
      prava intelektualnog vlasništva, Prodavatelj ne daje nikakva dodatna prava
      korištenja i objavljivanja, osim izrijekom dano pravo ili dozvola od
      proizvođača / distributera proizvoda.
    </p>

    <p>
      4. Kada „www.kokolingo.hr&#8221; odgovarajućim linkovima pruža mogućnost
      posjeta drugih web stranica drugih osoba, iste nisu u vlasništvu
      Prodavatelja i ovi Opći uvjeti u slučaju korištenja predmetnih web
      stranica na primjenjuju se u odnosu na Prodavatelja i Kupca. Prodavatelj
      te web stranice ne kontrolira i ne preuzima nikakvu odgovornost za bilo
      koju od njih ili njihov sadržaj. Posjet tim stranicama u cijelosti je na
      vlastiti rizik Kupca i Prodavatelj ne snosi nikakvu odgovornost.
    </p>

    <p>
      5. Iako Prodavatelj ulaže izraziti napor kako bi sve objavljene
      informacije o proizvodu bile točne, moguće je da informacije o određenom
      proizvodu nisu ažurirane ili točne, a u tom slučaju će Prodavatelj
      obavijestiti Kupca koji je podnio narudžbu nakon čega Kupac ima pravo
      ostati kod svoje narudžbe ili je izmijeniti. Fotografije uz proizvode samo
      su ilustrativne prirode.
    </p>

    <p>
      6. Prodavatelj zadržava pravo na objavljivanje sadržaja na engleskom
      jeziku u slučajevima kada:
    </p>

    <p>
      a) se radi o nazivu proizvoda, dijelova istih ili funkcija na engleskom
      jeziku
    </p>

    <p>
      b) se radi o izrazima na engleskom jeziku koji su općeprihvaćeni u
      hrvatskom jeziku odnosno da se radi o zajedničkoj terminologiji
    </p>

    <p>
      c) postoji rizik da se značajne informacije ne mogu dovoljno precizno
      prevesti na hrvatski jezik, a da se ne izgubi njihovo značenje i smisao
      <br />
    </p>
  </div>
);
