import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

import headerLogo from '../../../images/new-landing/header-logo.svg';

import appUrls from '../../../constants/appUrls';
import routes from '../../../routes';

import { ButtonNew } from '../../../components';

import './index.scss';

// eslint-disable-next-line react/prop-types
const TopBar = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuContainerClassName = `topbar-hr-menu-container ${
    isOpen ? 'topbar-hr-menu-container__open' : ''
  }`;

  const isKokolingoEkspert = title === 'Kokolingo Ekspert';

  return (
    <Row className="topbar-hr justify-content-center" noGutters>
      <div className="topbar-hr-child d-flex w-100 align-items-center">
        <div className="logo">
          <Link to="/">
            <img src={headerLogo} alt="Kokolingo logo" />
          </Link>
          <span
            className={`topbar-hr-hamburger ${
              isOpen ? 'topbar-hamburger-open' : 'topbar-hamburger-close'
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span />
            <span />
            <span />
          </span>
        </div>
        <div className={menuContainerClassName}>
          <Row noGutters>
            <Col className="menu order-1 order-xl-0 flex-xl-grow-1">
              <ul>
                <li>
                  <Link to="/" onClick={() => setIsOpen(!isOpen)}>
                    Početna
                  </Link>
                </li>
                <li>
                  <Link to={routes.EDUCATIONAL_CORNER}>Blog</Link>
                </li>
                <li>
                  <Link to={routes.FAQ}>FAQ</Link>
                </li>
              </ul>
            </Col>
            <Col className="login order-2 order-xl-1 col-xl-auto" xs={12}>
              <ButtonNew variant="ghost" size="small" onClick={appUrls.HOME}>
                Kokolingo aplikacija
              </ButtonNew>
            </Col>
            <Col className="register order-0 order-xl-2 col-xl-auto" xs={12}>
              <ButtonNew
                size="small"
                onClick={
                  isKokolingoEkspert
                    ? appUrls.TELETHERAPY_PHONE_CALL_FORM_URL
                    : routes.TELETHERAPY
                }
              >
                {isKokolingoEkspert ? 'Prijava' : 'Želim logopeda'}
              </ButtonNew>
            </Col>
            <Col className="order-3 order-xl-3 col-xl-auto" xs={12}>
              <div className="topbar-hr-spacer" />
            </Col>
            <Col className="academy order-4 order-xl-4 col-xl-auto" xs={12}>
              <ButtonNew
                size="small"
                variant="academy"
                onClick={routes.ACADEMY}
              >
                Akademija za predškolarce
              </ButtonNew>
            </Col>
          </Row>
        </div>
      </div>
    </Row>
  );
};

export default TopBar;
